strong {
  font-weight: bold !important;
}

em {
  font-style: italic !important;
}

sup {
  vertical-align: super;

  font-size: smaller;
}

sub {
  vertical-align: sub;

  font-size: smaller;
}

.ql-container {
  min-height: 8.75em;
}

.ql-toolbar {
  background-color: var(--ngx-blue-grey-light);

  button.ql-active {
    background-color: var(--ngx-button-active);

    border-radius: 5px;
  }
}

.error-border {
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid var(--texas-rose-color);
  }
}

.rich-text-container {
  ol,
  ul {
    margin-left: 2em !important;
  }

  ol {
    list-style: decimal !important;
  }

  ul {
    list-style: disc !important;
  }
}
