.card-item {
  width: 100%;
  height: auto;
  min-height: 114px;

  padding: 16px 32px;

  box-sizing: border-box;
  border: 1px solid var(--concrete-color);
  border-radius: 4px;

  background-color: var(--white-color);

  &.active {
    box-sizing: border-box;

    background-color: var(--black-lighten-transparent-color);
  }

  h4,
  .body-2 {
    color: var(--mine-shaft-color);

    &.company,
    &.broker {
      color: var(--boulder-color);
    }
  }
}

.ggi-input {
  &.search {
    span {
      position: absolute;

      margin-top: 11px;
      padding: 0 20px;

      font-size: 23px;
    }

    input {
      padding: 1.0313em 4.2813em;
    }
  }
}

.hidde {
  display: none;
}

.ql-editor {
  min-height: 112px;
}
