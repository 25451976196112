$primary-font: 'Biko', sans-serif;
$secondary-font: 'Century Gothic', sans-serif;

$primary-text-color: var(--primary-text-color);

.primary-text-color {
  color: $primary-text-color;
}

h1 {
  font-family: $primary-font;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  @extend .primary-text-color;
}

h2 {
  font-family: $primary-font;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  @extend .primary-text-color;
}

h3 {
  font-family: $primary-font;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  @extend .primary-text-color;
}

h4 {
  font-family: $primary-font;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  @extend .primary-text-color;
}

h4.title {
  font-family: $primary-font;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  color: var(--bay-of-many-color);
  @extend .primary-text-color;
}

.subtitule-1 {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 21px;
  @extend .primary-text-color;
}

.subtitule-bay-2 {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 21px;
  color: var(--bay-of-many-color);
}

.subtitule-2 {
  font-family: $primary-font;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  @extend .primary-text-color;
}

.text-button-a {
  text-decoration: none;
  color: var(--alizarin-crimson-color);
  @extend .subtitule-2;
}

.body-subtitle {
  font-family: $secondary-font;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  @extend .primary-text-color;
}

.body-1 {
  font-family: $secondary-font;
  font-size: 16px;
  line-height: 25px;
  @extend .primary-text-color;
}

.body-light-1 {
  @extend .body-1;
  color: var(--boulder-color);
}

.body-2 {
  font-family: $secondary-font;
  font-size: 14px;
  line-height: 23px;
  @extend .primary-text-color;
}

.body-light-2 {
  @extend .body-2;
  color: var(--boulder-color);
}

.text-small {
  font-family: $secondary-font;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  @extend .primary-text-color;
}

.text-tiny {
  font-family: $secondary-font;
  font-size: 12px;
  line-height: 17px;
  @extend .primary-text-color;
}

.url {
  cursor: pointer;

  color: var(--alizarin-crimson-color);
  font-family: $secondary-font;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;

  position: relative;

  top: -0.25em;
  left: 0.5em;
}
