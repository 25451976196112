.low,
.medium,
.high {
  display: flex;

  align-items: center;

  float: right;

  .circle {
    width: 0.688rem;
    min-width: 0.688rem;
    height: 0.688rem;

    margin-right: 0.438rem;

    border-radius: 50%;
  }
}

.low {
  color: var(--buttercup-color);

  .circle {
    background: var(--buttercup-color);
  }

  .text-tiny {
    color: var(--buttercup-color);
  }
}

.medium {
  color: var(--coral-color);

  .circle {
    background: var(--coral-color);
  }

  .text-tiny {
    color: var(--coral-color);
  }
}

.high {
  color: var(--alizarin-crimson-color);

  .circle {
    background: var(--alizarin-crimson-color);
  }

  .text-tiny {
    color: var(--alizarin-crimson-color);
  }
}
