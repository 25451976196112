@import 'device-sizes';

.primary-text-styles {
  font-family: 'Century Gothic';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  color: var(--mine-shaft-color);
}

.label-is-active {
  top: -5px !important;

  background: var(--white-color);

  padding: 0.3125em;

  margin: -0.3125em;

  font-family: 'Century Gothic';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
}

.ggi-textarea {
  position: relative;

  label {
    position: absolute;

    top: 1.0313em;
    left: 1.2813em;

    transition: all 0.2s;

    @extend .primary-text-styles;

    color: var(--boulder-color);
  }

  textarea {
    border: 1px solid var(--concrete-color);
    border-radius: 4px;

    background-color: var(--white-color);

    resize: none;

    width: 100%;

    padding: 1.0313em 1.2813em;

    transition: all 0.2s;

    @extend .primary-text-styles;

    &.ng-invalid.ng-touched {
      border-color: var(--texas-rose-color);
    }

    &.ng-invalid.ng-touched + label {
      @extend .label-is-active;

      color: var(--texas-rose-color);
    }

    &.ng-valid.ggi-optional:not(.ng-untouched) + label,
    &.ng-valid:not(.ggi-optional) + label {
      @extend .label-is-active;

      color: var(--mine-shaft-color);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label {
      cursor: not-allowed;
    }

    &:focus {
      border-color: var(--brand-secondary-color);

      box-shadow: 0 0 8px 0 rgba(31, 68, 137, 0.2);
    }

    &:focus + label {
      @extend .label-is-active;

      color: var(--brand-secondary-color);
    }
  }
}

.ggi-input {
  position: relative;

  label {
    position: absolute;

    top: 1.0313em;
    left: 1.2813em;

    transition: all 0.2s;

    @extend .primary-text-styles;

    color: var(--boulder-color);
  }

  input {
    width: 100%;

    border: 1px solid var(--concrete-color);
    border-radius: 2px;

    transition: all 0.2s;

    padding: 1.0313em 1.2813em;

    @extend .primary-text-styles;

    &.invalid-input {
      &.ng-invalid:not(form) + label {
        @extend .label-is-active;
      }
    }

    &.text-capitalize {
      text-transform: capitalize;
    }

    &.ng-invalid.ng-touched {
      border-color: var(--texas-rose-color);
    }

    &.ng-invalid.ng-touched + label {
      @extend .label-is-active;

      color: var(--texas-rose-color);
    }

    &.ng-valid.ggi-optional:not(.ng-untouched) + label,
    &.ng-valid:not(.ggi-optional) + label {
      @extend .label-is-active;

      color: var(--mine-shaft-color);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label {
      cursor: not-allowed;
    }

    &:focus {
      border-color: var(--brand-secondary-color);

      box-shadow: 0 0 8px 0 rgba(31, 68, 137, 0.2);
    }

    &:focus + label {
      @extend .label-is-active;

      color: var(--brand-secondary-color);
    }
  }

  &.password-input {
    position: relative;

    input {
      padding-right: 3.5em;
    }

    button {
      position: absolute;

      right: 0.5em;
      top: 0.4em;

      font-size: 1.5em;

      .icon-Eye-Opened::before {
        color: var(--bay-of-many-color);
        @include mobile {
          display: inline-block;
          margin-top: 0.375em;
        }
      }
      .icon-Eye-Closed::before {
        @include mobile {
          display: inline-block;
          margin-top: 0.375em;
        }
      }
    }
  }
}

.ggi-textarea,
.ggi-input {
  p {
    font-weight: normal;

    &.error {
      color: var(--texas-rose-color);
    }
  }
}

.native-login .ggi-input p.error {
  position: absolute;
}

.ggi-file {
  position: relative;

  border: 1px solid var(--alto-color);
  border-style: dotted;

  &.active {
    border: 1px solid var(--texas-rose-color);
    label {
      color: var(--texas-rose-color);
    }
  }

  label {
    position: absolute;

    top: -1.9687em;

    transition: all 0.2s;

    font-family: $primary-font;
    font-size: 14px;
    font-weight: 700;

    @extend .primary-text-color;
  }

  input[type='file'] {
    cursor: pointer;

    width: 100%;
    height: 86px;

    position: absolute;

    opacity: 0;

    border: 1px solid var(--concrete-color);
    border-radius: 2px;

    transition: all 0.2s;

    @extend .primary-text-styles;

    &.ng-invalid.ng-touched {
      border-color: var(--texas-rose-color);
    }

    &.ng-invalid.ng-touched + label {
      @extend .label-is-active;

      color: var(--texas-rose-color);
    }

    &.ng-valid + label {
      @extend .label-is-active;

      color: var(--mine-shaft-color);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label {
      cursor: not-allowed;
    }

    &:focus {
      border-color: var(--brand-secondary-color);

      box-shadow: 0 0 8px 0 rgba(31, 68, 137, 0.2);
    }

    &:focus + label {
      @extend .label-is-active;

      color: var(--brand-secondary-color);
    }
  }

  .file-container-wrapper {
    height: auto;
    min-height: 5.375em;

    padding: 0.48em;
  }

  .file-container {
    height: 4.5em;

    display: flex;

    align-items: center;
    justify-content: center;

    &.item {
      height: 4.5em;
      width: 100%;

      padding: 0 1em;

      margin-bottom: 1em;

      justify-content: space-between;

      border: 1px solid var(--concrete-color);
      border-radius: 0.1066em;
      box-sizing: border-box;

      background-color: var(--white-color);
      box-shadow: 0 2px 4px 0 var(--black-transparent-color);

      p {
        width: 70%;
      }
    }

    p {
      margin-right: 1em;
    }

    .btn-without-filled {
      height: 2.572em;

      padding: 0 1.122em;

      border: 0.04em solid var(--brand-primary-color);
    }
  }
}

.ggi-checkbox {
  position: relative;

  label {
    transition: all 0.2s;

    color: var(--boulder-color);

    position: relative;

    top: -0.25em;
    left: 0.5em;

    @extend .primary-text-styles;
  }

  input {
    height: 1.2em;
    width: 1.2em;

    cursor: pointer;

    position: relative;
    margin: 0px;

    -webkit-appearance: none;
    -mox-appearance: none;
    -o-appearance: none;
    appearance: none;

    outline: none;
    box-sizing: border-box;

    border: 1px solid var(--alto-color);
    border-radius: 4px;

    background-color: var(--white-color);

    &:before {
      content: '';

      width: 5px;
      height: 9px;

      display: block;
      position: absolute;

      left: 5px;
      top: 2px;

      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;

      transition: all 0.25s;

      transform: rotate(45deg);
    }

    &:checked {
      background-color: var(--alizarin-crimson-color);
      border: 1px solid var(--alizarin-crimson-color);

      &:before {
        border-bottom: 2px solid var(--white-color);
        border-right: 2px solid var(--white-color);
      }
    }

    &.ng-invalid.ng-touched {
      border-color: var(--texas-rose-color);
    }

    &.ng-invalid.ng-touched + label {
      @extend .label-is-active;

      color: var(--texas-rose-color);
    }

    &.ng-valid + label {
      color: var(--mine-shaft-color);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:disabled + label {
      cursor: not-allowed;
    }

    &:focus {
      border-color: var(--brand-primary-color);

      box-shadow: 0 0 8px 0 rgba(31, 68, 137, 0.2);
    }
  }
}

.radios {
  display: flex;

  .ggi-radio {
    display: flex;

    label {
      transition: all 0.2s;

      color: var(--mine-shaft-color);

      margin-left: 0.45em;

      @extend .primary-text-styles;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      width: 19px;
      height: 19px;

      padding: 2px;

      background-clip: content-box;

      border: 2px solid var(--alto-color);

      background-color: var(--white-color);

      border-radius: 50%;

      &.ng-invalid.ng-touched {
        border-color: var(--texas-rose-color);
      }

      &.ng-invalid.ng-touched + label {
        color: var(--texas-rose-color);
      }

      &:checked {
        background-color: var(--alizarin-crimson-color);
        border: 2px solid var(--alizarin-crimson-color);
      }

      &:disabled,
      &:disabled + label {
        cursor: not-allowed;
      }
    }

    &:nth-child(n + 2) {
      margin-left: 3em;
    }
  }

  &.column {
    flex-direction: column;

    .ggi-radio {
      margin-left: 0;

      flex-wrap: wrap;

      align-items: center;

      label {
        width: 85%;

        overflow-wrap: break-word;
      }

      &:nth-child(n + 2) {
        margin-top: 0.6em;
      }
    }
  }
}

.search {
  padding: 14px 20px;
  border-radius: 2px;
  border: 1px solid var(--grey-ultra-ligh-color);
  label {
    display: flex;
    span {
      font-size: 24px;
    }
    input {
      border: none;
      margin-left: 16px;
      width: calc(100% - 60px);
      @extend .primary-text-styles;
    }
  }
}

.ggi-switch {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 22px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--brand-primary-color);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--lime-green-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--lime-green-color);
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

p.text-tiny.error {
  color: var(--texas-rose-color);
}

.ggi-input-range {
  input[type='range'] {
    cursor: pointer;

    display: block;

    width: 45%;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 20px;
    height: 20px;

    background-color: var(--alizarin-crimson-color);

    border: 3px solid var(--alizarin-crimson-color);
    border-radius: 50%;

    margin-top: -9px;
  }

  input[type='range']::-moz-range-thumb {
    width: 15px;
    height: 15px;

    background-color: var(--alizarin-crimson-color);

    border: 3px solid var(--alizarin-crimson-color);
    border-radius: 50%;
  }

  input[type='range']::-ms-thumb {
    width: 20px;
    height: 20px;

    background-color: var(--alizarin-crimson-color);

    border: 3px solid var(--alizarin-crimson-color);
    border-radius: 50%;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 3px;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    outline: none;
  }

  input[type='range']::-moz-range-track {
    height: 3px;
  }

  input[type='range']::-ms-track {
    height: 3px;
  }

  #myinput {
    border-radius: 8px;
    height: 4px;
    width: 45%;
    outline: none;
    -webkit-appearance: none;
    background-color: #dee2e6;
  }
}

.radios-content {
  margin-top: 2rem;

  .ggi-radio {
    display: flex;
    align-items: center;

    margin-bottom: 1.5rem;

    .checkbox-question {
      margin-right: 0.75rem;
    }

    .ggi-input {
      width: 33%;
    }
  }
}

.ggi-radio {
  display: block;
  position: relative;

  padding-left: 35px;
  margin-bottom: 12px;

  font-size: 22px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input.checkbox-question {
    position: absolute;

    opacity: 0;

    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;

    height: 1.375rem;
    width: 1.375rem;

    border-radius: 0.25rem;
    border: 0.125rem solid var(--mine-shaft-color);
    background-color: var(--white-color);

    &.open {
      top: 0.8rem;
    }
  }

  input.checkbox-question:checked ~ .checkmark {
    background-color: var(--mine-shaft-color);
  }

  .checkmark:after {
    display: none;
    content: '';
    position: absolute;
  }

  input.checkbox-question:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 0.3125rem;
    top: 0.125rem;

    width: 0.25rem;
    height: 0.5rem;

    border: solid var(--white-color);
    border-width: 0 0.1875rem 0.1875rem 0;

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
