:root {
  --alizarin-crimson-color: #e91e2d;
  --bay-of-many-color: #1f4489;
  --buttercup-color: #f4a81e;
  --mine-shaft-color: #373737;
  --boulder-color: #7a7a7a;
  --dim-color: #7b7b7b;
  --alto-color: #909090;
  --concrete-color: #f2f2f2;
  --alabaster-color: #fbfbfb;
  --azure-radiance: #1877f2;
  --de-york-color: #8dc687;
  --fern-color: #73b26c;
  --lime-green-color: #3ad07c;
  --apricot-color: #ed7070;
  --roman-color: #d75f5f;
  --white-color: #ffffff;
  --black-color: #000;
  --denim-color: #1864c6;
  --alabaster-color-lighten: #fafafa;
  --texas-rose-color: #ffa856;
  --gainsboro-color: #d7d7d7;
  --grey-ultra-ligh-color: #e8e8e8;
  --coral-color: #ff7329;
  --nobel-color: #9d9d9d;
  --very-light-grey-color: #cecece;
  --ngx-blue-grey-light: #e7eaef;
  --ngx-button-active: #d5deef;
  --taupe-gray-color: #898989;
  --bright-gray-color: #e9ecf3;

  --brand-primary-color: var(--alizarin-crimson-color);
  --brand-secondary-color: var(--bay-of-many-color);
  --brand-third-color: var(--buttercup-color);

  --blue-transparent-color: rgba(31, 68, 137, 0.8);
  --blue-lighten-transparent-color: rgba(31, 68, 137, 0.2);
  --red-transparent-color: rgba(233, 30, 45, 0.05);
  --red-button-color: rgba(255, 0, 0, 0.562);
  --black-transparent-color: rgba(0, 0, 0, 0.04);
  --black-lighten-transparent-color: rgba(31, 68, 137, 0.05);
  --black-lighten-transparent: rgba(0, 0, 0, 0.04);
  --black-ligh-transparent: rgba(55, 55, 55, 0.94);
  --black-ligh-transparent-color: rgba(0, 0, 0, 0.12);
  --grey-transparent-color: rgba(255, 255, 255, 0.1);
  --transparent: rgba(255, 255, 255, 0);

  --grey-color: var(--boulder-color);
  --grey-lighten-color: var(--alto-color);
  --silver-color: var(--concrete-color);
  --silver-lighten-color: var(--alabaster-color);

  --facebook-color: var(--azure-radiance);
  --facebook-hover-color: var(--denim-color);

  --google-color: var(--alabaster-color);
  --google-hover-color: var(--concrete-color);

  --primary-text-color: var(--mine-shaft-color);

  --success-color: var(--de-york-color);
  --success-darkness-color: var(--fern-color);

  --error-color: var(--apricot-color);
  --error-darkness-color: var(--roman-color);

  --primary-background-color: var(--alabaster-color);
}
