@import 'device-sizes';

.alert {
  max-width: 507px;
  min-height: 79px;

  display: inline-flex;

  padding: 21px 61px 23px 24px;

  color: var(--white-color);

  border-radius: 8px;

  position: absolute;
  right: -6px;

  @include mobile {
    right: 2.25;
  }
  @include tablet-max-width {
    right: 0px;
  }
  .alert-close {
    position: absolute;

    top: -5px;
    right: 7px;

    background: none;
    border: none;

    opacity: 0.31;

    color: var(--white-color);

    font-size: 30px;
  }

  .alert-icon {
    width: 35px;
    height: 35px;

    margin-right: 20px;

    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: var(--white-color);
    }
  }

  .alert-text {
    width: calc(100% - 55px);

    font-family: 'Century Gothic';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
  }
}

.alert-success {
  @extend .alert;

  background: var(--success-color);

  .alert-icon {
    background: var(--success-darkness-color);
  }
}

.alert-error {
  @extend .alert;

  background: var(--error-color);

  .alert-icon {
    background: var(--error-darkness-color);
  }
}
