@font-face {
  font-family: 'Biko';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/assets/fonts/Biko/BikoRegular.ttf') format('ttf'),
    url('/assets/fonts/Biko/BikoRegular.woff2') format('woff2'),
    url('/assets/fonts/Biko/BikoRegular.eot') format('eot'),
    url('/assets/fonts/Biko/BikoRegular.otf') format('otf'),
    url('/assets/fonts/Biko/BikoRegular.woff') format('woff'),
    url('/assets/fonts/Biko/BikoRegular.svg') format('svg');
}

@font-face {
  font-family: 'Biko';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/Biko/Biko-Bold.ttf') format('ttf'),
    url('/assets/fonts/Biko/Biko-Bold.woff2') format('woff2'),
    url('/assets/fonts/Biko/Biko-Bold.eot') format('eot'),
    url('/assets/fonts/Biko/Biko-Bold.otf') format('otf'),
    url('/assets/fonts/Biko/Biko-Bold.woff') format('woff'),
    url('/assets/fonts/Biko/Biko-Bold.svg') format('svg');
}

@font-face {
  font-family: 'Century Gothic';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/assets/fonts/Century-Gothic/CenturyGothic-Regular.woff2')
      format('woff2'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Regular.eot') format('eot'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Regular.otf') format('otf'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Regular.svg') format('svg'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Regular.ttf') format('ttf'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Century Gothic';
  font-weight: 700;
  src: url('/assets/fonts/Century-Gothic/CenturyGothic-Bold.woff2')
      format('woff2'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Bold.eot') format('eot'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Bold.otf') format('otf'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Bold.svg') format('svg'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Bold.ttf') format('ttf'),
    url('/assets/fonts/Century-Gothic/CenturyGothic-Bold.woff') format('woff');
}
