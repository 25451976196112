.ggi-tab-container {
  display: flex;

  box-sizing: border-box;

  border-bottom: 1px solid #f2f2f2;

  .ggi-tab {
    cursor: pointer;

    margin-right: 40px;
    margin-bottom: 10px;

    &.active {
      margin-bottom: 0;

      padding-bottom: 10px;

      border-bottom: 1px solid var(--bay-of-many-color);

      p {
        color: var(--bay-of-many-color);
      }
    }
  }
}

.ggi-tab-expand {
  justify-content: space-around;

  .ggi-tab {
    margin-right: 0;

    width: 100%;

    text-align: center;
  }
}

.property-flow-steps {
  display: flex;

  margin-bottom: 3rem;

  font-weight: bold;
  font-size: 1.25rem;
  font-family: 'Century Gothic', sans-serif;
  color: var(--alizarin-crimson-color);

  .step {
    width: 2rem;
    height: 2rem;
    padding: 0.45rem 0.6rem;

    font-size: 14px;
    color: var(--alizarin-crimson-color);
    border-radius: 50%;
    border: 2px solid var(--alizarin-crimson-color);

    &.active {
      color: var(--white-color);
      background-color: var(--alizarin-crimson-color);
    }
  }

  .line {
    width: 1.5rem;
    height: 0;
    margin: 1rem 0.25rem;

    border-bottom: 2px solid var(--alizarin-crimson-color);
  }

  span {
    line-height: 2rem;
    margin-left: 1rem;
  }
}
