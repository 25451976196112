.ggi-tooltip {
  position: relative;

  display: inline-block;

  & .ggi-tooltiptext {
    visibility: hidden;

    width: 10em;

    background-color: var(--mine-shaft-color);
    color: #fff;

    text-align: center;

    border-radius: 6px;

    padding: 5px 0;

    position: absolute;

    z-index: 1;

    bottom: 125%;
    left: 50%;

    margin-left: -60px;

    opacity: 0;

    transition: opacity 0.3s;
  }

  & .ggi-tooltiptext::after {
    content: '';

    position: absolute;

    top: 100%;
    left: 50%;

    margin-left: -5px;

    border: 5px;
    border-style: solid;
    border-color: var(--mine-shaft-color) transparent transparent transparent;
  }

  &:hover .ggi-tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.ggi-tooltip-bottom {
  .ggi-tooltiptext {
    top: 125%;
    bottom: auto;

    &:after {
      top: auto;
      bottom: 100%;

      transform: rotate(180deg);
    }
  }
}
