.chip-active {
  background: var(--bay-of-many-color) !important;

  border-color: var(--bay-of-many-color) !important;

  color: var(--white-color) !important;
}

.chip {
  padding: 10.5px 27.5px 11.5px 24.5px;

  border: 1px solid transparent;
  border-radius: 100px;

  font-family: 'Century Gothic', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;

  color: var(--mine-shaft-color);

  border: 1px solid var(--concrete-color);

  background-color: var(--white-color);

  &:hover {
    background: var(--alabaster-color);

    border-color: var(--concrete-color);

    color: var(--mine-shaft-color);
  }

  &.active {
    @extend .chip-active;
  }

  &:disabled {
    color: var(--concrete-color);
  }

  &-close {
    cursor: pointer;

    position: absolute;

    border-radius: 0 100px 100px 0;

    display: inline-flex;
    justify-content: center;

    align-items: center;

    right: 0;
    top: 0;

    width: 36px;
    height: 100%;

    background-color: #18366e;
  }

  .icon-Close::before {
    font-size: 16px;

    color: white;
  }
}

.chip-checkbox {
  input[type='checkbox'] {
    display: none;

    &:checked + label {
      @extend .chip-active;
    }
  }

  label {
    display: table;
  }
}

.rectangle-checkbox {
  input[type='checkbox'] {
    display: none;

    &:checked + label {
      color: var(--white-color);

      background: var(--brand-primary-color);
    }
  }

  label {
    width: 91%;

    display: table;

    color: var(--brand-primary-color);
    background: transparent;
    border: 1px solid var(--brand-primary-color);

    margin: auto;
    padding: 18px;

    border-radius: 4px;

    font-size: 13px;
    font-weight: bold;
  }
}
