$device-size-web-min-width: 80em; //1280px
$device-size-web-max-width: 90em; //1440px
$device-size-tablet-min-width: 48em; //768px
$device-size-tablet-max-width: 61.938em; //991px
$device-size-mobile-max-width: 29.875em; //478px
$device-size-mobile-landscape: 53.125em; //850px

@mixin web-xl {
  @media (max-width:#{$device-size-web-min-width}) {
    @content;
  }
}

@mixin web {
  @media (min-width: #{$device-size-web-min-width}) and (max-width: #{$device-size-web-max-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$device-size-tablet-min-width}) and (max-width: #{$device-size-tablet-max-width}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: #{$device-size-tablet-max-width}) and (max-width: #{$device-size-web-min-width}) {
    @content;
  }
}

@mixin tablet-max-width {
  @media (max-width: #{$device-size-tablet-max-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$device-size-mobile-max-width}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: #{$device-size-mobile-landscape}) and (orientation: landscape) {
    @content;
  }
}
