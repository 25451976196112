$overlay-color: #000;

.modal {
  width: 100%;
  height: 100vh;

  background: rgba($color: $overlay-color, $alpha: 0.5);

  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1001;

  top: 0;
  left: 0;

  .modal-content {
    max-width: 721px;
    width: 100%;
    max-height: calc(100vh - 4em);

    border-radius: 8px;

    background-color: var(--white-color);

    padding: 1.75em 2.2em 0 2.2em;

    box-shadow: 0 2px 15px 0 rgba($color: $overlay-color, $alpha: 0.06);

    overflow: auto;

    .modal-title {
      position: relative;

      padding-right: 2.2em;

      display: flex;
      justify-content: space-between;
    }

    .modal-close {
      position: absolute;

      right: -0.75em;
      top: -0.75em;

      display: flex;
      justify-content: flex-end;

      button {
        background: none;
        border: none;

        color: var(--brand-primary-color);

        font-size: 30px;
      }
    }

    &::after {
      content: '';

      height: 2.8em;

      display: block;
    }
  }

  .modal-actions {
    margin-top: 2em;

    &-right {
      text-align: right;

      button:not(:last-child) {
        margin-right: 1em;
      }
    }

    &-center {
      button {
        display: block;

        margin: 1em auto 0 auto;
      }
    }

    &-large {
      display: flex;

      justify-content: space-between;

      button {
        width: 48%;
      }
    }
  }
}

.icon-sent {
  width: 4rem;
  margin-bottom: 1.375rem;
  margin-top: -1rem;
}

@media (max-width: 769px) {
  .modal {
    .modal-content {
      max-width: 80%;
    }
  }
}
