$brand-color: var(--brand-secondary-color);

.ggi-calendar,
.calendar .ggi-input {
  width: 100%;

  font-size: 16px;

  .icon-Calendar {
    position: absolute;

    top: 0.7em;
    right: 1em;

    &::before {
      font-size: 24px;

      color: var(--brand-secondary-color);
    }
  }

  input {
    cursor: pointer;

    position: relative;

    z-index: 20;

    background-color: var(--transparent);
  }

  .md-drppicker {
    box-shadow: none;

    font-family: 'Biko';
    font-size: 14px;

    width: 100%;

    .calendar-time {
      margin: 0.938em 0;
    }

    .calendar {
      max-width: 100%;
      width: 100%;

      .week-days {
        th {
          color: black;

          text-transform: uppercase;
          font-family: 'Biko';

          padding-top: 2.125em;

          span {
            width: 2.5em;
            height: 2.5em;

            display: block;
          }
        }
      }

      tbody {
        td {
          font-family: 'Century Gothic';

          span {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 2.5em;
            height: 2.5em;

            border-radius: 50%;
          }

          &.active {
            span {
              background: $brand-color;
            }
          }
        }
      }

      .month {
        font-family: 'Biko';

        color: var(--mine-shaft-color);

        vertical-align: middle;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

.calendar .ggi-input {
  .md-drppicker {
    box-shadow: 0 2px 4px 0 var(--black-lighten-transparent);

    border: 1px solid var(--concrete-color);
    border-radius: 4px;

    background-color: var(--white-color);

    width: 20em;
    left: 0 !important;
  }

  &.range .md-drppicker {
    width: 35rem;

    left: -10rem !important;
    right: auto !important;

    .calendar {
      width: unset;
      max-width: unset;
    }
  }
}

.conteiner-calendar-and-hours .md-drppicker td.active,
.conteiner-calendar-and-hours .md-drppicker td:hover {
  background: none !important;
}

.conteiner-calendar-and-hours.property-flow .month.drp-animate {
  font-weight: bold;
  color: var(--bay-of-many-color) !important;
}

@media (max-width: 1024px) {
  .ggi-calendar {
    font-size: 12px;
  }
}

@media (max-width: 800px) {
  .ggi-calendar .md-drppicker .calendar {
    .drp-animate td,
    .calendar .ggi-input .md-drppicker .calendar .drp-animate td {
      text-align: -webkit-center;
    }

    .week-days th {
      text-align: -webkit-center;
    }
  }
}
