@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon/ggi-journey-iconfont.eot');
  src: url('/assets/fonts/icomoon/ggi-journey-iconfont.eot?z0dmpp#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/icomoon/ggi-journey-iconfont.woff2?z0dmpp')
      format('woff2'),
    url('/assets/fonts/icomoon/ggi-journey-iconfont.woff?z0dmpp') format('woff'),
    url('/assets/fonts/icomoon/ggi-journey-iconfont.ttf?z0dmpp')
      format('truetype'),
    url('/assets/fonts/icomoon/ggi-journey-iconfont.svg?z0dmpp#ggi-journey-iconfont')
      format('svg');
  font-style: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change /assets/fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icons-center {
  display: flex;

  align-items: center;

  span {
    margin-right: 0.3rem;
  }
}

.icon-Close:before {
  content: '\ea09';
  color: #e91e2d;
}
.icon-Add-Guest:before {
  content: '\ea02';
  color: #e91e2d;
}
.icon-Password::before {
  content: '\ea1e';
  color: #1f4489;
}
.icon-Arrow-Dropdown:before {
  content: '\ea04';
  color: #e91e2d;
}
.icon-Contact:before {
  content: '\ea0e';
  color: #1f4489;
}
.icon-Interests-Blue:before {
  content: '\ea1b';
  color: #1f4489;
}
.icon-Google:before {
  content: '\ea16';
  color: rgb(66, 133, 244);
}
.icon-Pdf:before {
  content: '\ea1f';
  color: #e91e2d;
}
.icon-Share:before {
  content: '\ea29';
  color: #e91e2d;
}
.icon-Add:before {
  content: '\ea01';
  color: #e91e2d;
}
.icon-Tour-Virtual:before {
  content: '\ea2a';
  color: #e91e2d;
}
.icon-Cancel-Appointment:before {
  content: '\ea08';
  color: #e91e2d;
}
.icon-Interests:before {
  content: '\ea1b';
  color: #e91e2d;
}
.icon-Info:before {
  content: '\ea1a';
  color: #e91e2d;
}
.icon-Notes:before {
  content: '\ea1c';
  color: #e91e2d;
}
.icon-Docs:before {
  content: '\ea10';
  color: #e91e2d;
}
.icon-Calendar:before {
  content: '\ea07';
  color: #e91e2d;
}
.icon-History:before {
  content: '\ea18';
  color: #e91e2d;
}
.icon-Guests:before {
  content: '\ea17';
  color: #e91e2d;
}
.icon-Google .path1:before {
  content: '\ea16';
  color: rgb(66, 133, 244);
}
.icon-Google .path2:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-Google .path3:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(251, 188, 4);
}
.icon-Google .path4:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-Facebook:before {
  content: '\ea14';
  color: #e91e2d;
}
.icon-Copy:before {
  content: '\ea0f';
  color: #e91e2d;
}
.icon-Arrow-Front:before {
  content: '\ea05';
  color: #e91e2d;
}
.icon-Clos:before {
  content: '\ea0e';
  color: #ffffff;
}
.icon-Download:before {
  content: '\ea11';
  color: #e91e2d;
}
.icon-Arrow-Back:before {
  content: '\ea03';
  color: #e91e2d;
  font-weight: 900;
}
.icon-Send:before {
  content: '\ea28';
  color: #e91e2d;
}
.icon-Properties:before {
  content: '\ea20';
  color: #1f4489;
}
.icon-Properties-Available:before {
  content: '\ea21';
  color: #1f4489;
}
.icon-In-Progress:before {
  content: '\ea19';
  color: #1f4489;
}
.icon-Closed-Sales:before {
  content: '\ea0a';
  color: #1f4489;
}
.icon-Property-Residential:before {
  content: '\ea26';
  color: #1f4489;
}
.icon-Property-Commercial:before {
  content: '\ea24';
  color: #1f4489;
}
.icon-Property-Corporate:before {
  content: '\ea25';
  color: #1f4489;
}
.icon-Eye-Closed::before {
  content: '\ea12';
  color: #1f4489;
}
.icon-Eye-Opened::before {
  content: '\ea13';
  color: #e91e2d;
}
.icon-Filter:before {
  content: '\ea15';
  color: #e91e2d;
}
.icon-Order:before {
  content: '\ea1d';
  color: #e91e2d;
}
.icon-Search:before {
  content: '\ea27';
  color: #1f4489;
}
.icon-Company-Edit:before {
  content: '\ea0c';
  color: #1f4489;
}
.icon-Call-Add:before {
  content: '\ea2c';
  color: #373737;
}
.icon-Call-Mic:before {
  content: '\ea2e';
  color: #373737;
}
.icon-Call-Video:before {
  content: '\ea2f';
  color: #373737;
}
.icon-Call-Hangup:before {
  content: '\ea2d';
  color: #373737;
}
.icon-Specs:before {
  content: '\ea31';
  color: #cecece;
}
.icon-Instalations:before {
  content: '\ea30';
  color: #cecece;
}
.icon-Specs-Building:before {
  content: '\ea32';
  color: #cecece;
}
.icon-Doc:before {
  content: '\ea10';
  color: #cecece;
}
.icon-Doc-2:before {
  content: '\ea10';
  color: #1f4489;
}
.icon-Arrow-Up-Left:before {
  content: '\ea2b';
  color: #ffffff;
}
.icon-Company::before {
  content: '\ea0b';
  color: #1f4489;
}
.icon-Company-Assigned:before {
  content: '\ea0b';
  color: #1f4489;
}
.icon-Company-New::before {
  content: '\ea0d';
  color: #1f4489;
}
.icon-Property-Available:before {
  content: '\ea23';
  color: #1f4489;
}
.icon-Broker-Edit::before {
  content: '\ea34';
  color: #e91e2d;
}
.icon-Delete::before {
  content: '\ea35';
  color: #e91e2d;
}
