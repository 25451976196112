button {
  border: none;
  background: transparent;
}

.button {
  padding: 0.813em 2.094em;

  width: auto;

  border-radius: 4px;
  border: 1px solid transparent;

  font-family: 'Century Gothic', sans-serif;
  font-size: 14px;
  font-weight: 700;

  line-height: 20px;

  text-decoration: none;
  display: inline-block;

  &:disabled {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }
}

.btn-filled {
  @extend .button;

  color: var(--white-color);

  background: var(--brand-primary-color);

  &:disabled {
    background: var(--red-button-color);
  }
}

.btn-without-filled {
  @extend .button;

  color: var(--brand-primary-color);
  background: transparent;

  border: 1px solid var(--brand-primary-color);

  &.without-border {
    cursor: pointer;

    border: none;
  }

  &:hover {
    background: var(--red-transparent-color);
  }
}

.btn-facebook {
  @extend .button;

  color: var(--white-color);
  background: var(--facebook-color);

  &:hover {
    background: var(--facebook-hover-color);
  }
}

.btn-google {
  @extend .button;

  color: var(--mine-shaft-color);
  background: var(--google-color);

  &:hover {
    background: var(--google-hover-color);
  }
}

.btn-delete-file {
  cursor: pointer;

  font-family: 'Century Gothic', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;

  color: var(--bay-of-many-color);
  background: var(--white-color);

  border: none;

  z-index: 999;

  &:hover {
    color: var(--blue-transparent-color);
  }
}

.btn-red {
  color: var(--alizarin-crimson-color);
  @extend .button;
}

.btn-small {
  line-height: 2px;

  padding: 14px 26px 18px 26px !important;
}

.buttons-social-media {
  .btn-google,
  .btn-facebook {
    width: 100%;

    margin: 0.625rem 0;

    .container-button {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      .container-icon {
        margin-right: 1rem;
      }
    }
  }

  p {
    text-align: center;
  }

  .icon-Facebook::before {
    font-size: 22px;

    color: var(--white-color);
  }
}
